
// Carousel fade
// ----------------------------------------------

.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
        }

        .item,
        .active.left,
        .active.right {
            opacity: 0;
        }

        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }

        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }

    .carousel-control {
        z-index: 2;
    }
}

// Carousel overlay effect (option)
// ----------------------------------------------


.figure-layout {

    &::after {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(black, .3);
        background: linear-gradient(0deg, rgba(black, .5) 0%, rgba(black, 0) 100%);
    }
}


// Carousel accueil
// ----------------------------------------------

.carousel-accueil__img-cover {
    display: block;
    width: 100%;
    min-height: 35rem;
    height: 100%;

    @media(min-width: $screen-md-min) {
        min-height: 35rem;
    }
}

// Carousel header
// ----------------------------------------------

.carousel-header__img-cover {
    display: block;
    width: 100%;
    min-height: 35rem;
    height: 100%;
}

// Carousel Bootstrap
// ----------------------------------------------

.carousel-bootstrap__img-cover {
    display: block;
    width: 100%;
    min-height: 30rem;
    height: 100%;

    @media(min-width: $screen-md-min) {
        min-height: 27rem;
    }
}

// Caption custom position
// ----------------------------------------------

.slider-header__carousel-caption {
    // @extend .text-center; // pour centrer le caption
    position: absolute;
    z-index: 10;
    left: 10%;
    bottom: 15%;
    right: 10%;

    @media(min-width: $screen-md-min) {
        left: 15%;
        right: 15%;
    }

}

// Caption button
// ----------------------------------------------

.slider-header__btn {
    @extend .btn;
    @extend .btn-lg;
    @extend .btn-success;
    @extend .animated;
    @extend .fadeInRight;
    animation-delay: 1.5s;
    margin-top: 1rem;

    // @media(min-width: $screen-sm-min) {
    //     padding: 1rem 2rem;
    //     margin-top: 2rem;
    //     font-size: $font-size-large;
    //     font-weight: 600;
    // }
}

// Caption text
// ----------------------------------------------

.slider-header__titre-1 {
    @extend .animated;
    @extend .fadeInRight;
    @include font-size(3rem);
    animation-delay: .5s;

    >span {
        display: inline-block;
        padding: .5% 0;
    }
}

.slider-header__titre-2 {
    @extend .animated;
    @extend .fadeInRight;
    @include font-size(1.5rem);
    animation-delay: 1s;

    >span {
        display: inline-block;
        padding: .5% 0;
    }
}

// Caption colors text
// ----------------------------------------------

// --- titre 1 blanc sur fond transparent
.slider-header__titre-1-T-W {
    @extend .slider-header__titre-1;

    >span {
        color: white;
    }
}

// --- titre 2 blanc sur fond transparent
.slider-header__titre-2-T-W {
    @extend .slider-header__titre-2;

    >span {
        color: white;
    }
}

// --- titre 1 noir sur fond transparent
.slider-header__titre-1-T-B {
    @extend .slider-header__titre-1;
    color: black;

    >span {
        color: black;
    }
}

// --- titre 2 noir sur fond transparent
.slider-header__titre-2-T-B {
    @extend .slider-header__titre-2;

    >span {
        color: black;
    }
}

// --- titre 1 blanc sur fond noir
.slider-header__titre-1-B {
    @extend .slider-header__titre-1;

    >span {
        background-color: rgba(black, .5);
        color: white;
    }
}

// --- titre2 blanc sur fond noir
.slider-header__titre-2-B {
    @extend .slider-header__titre-2;

    >span {
        background-color: rgba(black, .5);
        color: white;
    }
}

// --- titre 1 noir sur fond blanc
.slider-header__titre-1-W {
    @extend .slider-header__titre-1;

    >span {
        background-color: rgba(white, .5);
        color: black;
    }
}

// --- titre 2 noir sur fond blanc
.slider-header__titre-2-W {
    @extend .slider-header__titre-2;

    >span {
        background-color: rgba(white, .5);
        color: black;
    }
}
